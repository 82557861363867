import { FeedbackNotificationProps } from '@clarke-energia/foton';

export const errorClipboardFeedbackNotificationContents = (
  onCloseNotification: () => void,
): Record<string, FeedbackNotificationProps> => {
  return {
    COPY_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'Dados de descrição detalhada do erro foram copiados.',
      timeToCloseAutomaticallyInSeconds: 5,
      onCloseNotification,
    },
    COPY_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Não foi possível copiar os dados de descrição detalhada do erro.',
      timeToCloseAutomaticallyInSeconds: 5,
      onCloseNotification,
    },
  };
};

export const writeUserClipboard = (formValues: string) => {
  return navigator.clipboard.writeText(formValues);
};

export const setErrorClipboardValue = (errorData: string) => {
  localStorage.setItem('ERROR_CLIPBOARD', errorData);
};

export const generateTextFromErrorData = (
  errorData: any,
  leadName: string | undefined,
  simulatorName: string,
): string => {
  let markdown = `# Error Report\n\n`;
  if (simulatorName) {
    markdown += `**Simulador:** ${simulatorName}\n\n`;
  }

  if (leadName) {
    markdown += `**Lead:** ${leadName}\n\n`;
  }

  if (errorData.errorCode) {
    markdown += `**Código de Erro:** ${errorData.errorCode}\n\n`;
  }

  if (errorData.errorMessagePt) {
    markdown += `**Mensagem de Erro:** ${errorData.errorMessagePt}\n\n`;
  }

  if (errorData.errorFieldsDescription) {
    markdown += `**Descrição de campos com erro:**\n`;
    markdown += `${JSON.stringify(errorData.errorFieldsDescription, null, 2)}\n\`\`\`\n`;
    markdown += `\n`;
  }

  if (errorData.requestParams) {
    markdown += `**Parametros relevantes:**\n`;
    markdown += `${JSON.stringify(errorData.requestParams, null, 2)}\n\`\`\`\n`;
    markdown += `\n`;
  }

  if (errorData.inputs) {
    markdown += `**Inputs:**\n`;
    markdown += `${JSON.stringify(errorData.inputs, null, 2)}\n\`\`\`\n`;
  }

  return markdown;
};
