import React from 'react';
import { Button, ButtonProps } from '@clarke-energia/foton';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { getCalculatorName } from '@utils/common';
import config from '@config';

import useSales from '@hooks/use-sales';
import { SimulatorTypeEnum } from '@schemas/types';
import { useAuth } from '@src/auth-wrapper';

type ProposalDocumentLayoutType = 'NORMAL' | 'MEDIUM' | 'LARGE';

interface DocumentLayoutDimensions {
  width: number;
  height: number;
}

interface ICreateProposalDocumentButton {
  simulationUrl: string;
  setPdfLink: React.Dispatch<React.SetStateAction<string | undefined>>;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | undefined>>;
  kind?: ButtonProps['kind'];
  leadName?: string;
  isButtonSmallSize?: boolean;
  simulatorType: keyof typeof SimulatorTypeEnum;
  disabled?: boolean;
}

const getProposalDocumentDimensions = (simulatorType: keyof typeof SimulatorTypeEnum) => {
  const simulatorTypeIndexer: Record<keyof typeof SimulatorTypeEnum, ProposalDocumentLayoutType> = {
    CLARKE_MANAGEMENT: 'MEDIUM',
    CLARKE_RETAIL: 'MEDIUM',
    WHITE_LABEL: 'MEDIUM',
    CONTRACTED_DEMAND: 'NORMAL',
  };

  const layoutTypeIndexer: Record<ProposalDocumentLayoutType, DocumentLayoutDimensions> = {
    NORMAL: { width: 1366, height: 852 },
    MEDIUM: { width: 1440, height: 810 },
    LARGE: { width: 1920, height: 1080 },
  };

  const layoutType = simulatorTypeIndexer[simulatorType];
  const documentDimensions = layoutTypeIndexer[layoutType];

  return documentDimensions;
};

const CreateProposalDocumentButton: React.FC<ICreateProposalDocumentButton> = ({
  kind = 'primary',
  simulationUrl,
  leadName,
  setPdfLink,
  setErrorMessage,
  simulatorType,
  isButtonSmallSize,
  disabled,
}) => {
  const { user } = useAuth();
  const { convertHtmlToPdfHandler } = useSales();
  const [loadingPdf, setLoadingPdf] = React.useState(false);

  const { width, height } = getProposalDocumentDimensions(simulatorType);
  const simulationHref = `${config.SERVER_BASE_URL}${simulationUrl}?print`;
  const documentName = `${leadName}-${Math.floor(Math.random() * 1000)}`;

  const pdfGeneratorApiVersion = 'v2';

  const generateNewDocumentHandler = () => {
    if (setPdfLink) {
      setLoadingPdf(true);
      setPdfLink(undefined);
      convertHtmlToPdfHandler(simulationHref, documentName, width, height, pdfGeneratorApiVersion)
        .then((res) => {
          setLoadingPdf(false);
          setPdfLink(res.data.data.url);
          setErrorMessage(undefined);
        })
        .catch((error) => {
          setLoadingPdf(false);
          setPdfLink(undefined);
          setErrorMessage(error.response.data.message);
        });

      eventTracker.trackEvent(eventLabels.BUTTON_GENERATE_DOCUMENT_DRAWER, {
        product: getCalculatorName(location.href),
        user_groups: user?.groups ? user?.groups.join(', ') : '',
        user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
      });
    }
  };

  return (
    <Button
      customAttrs={{ 'data-cy': 'create-proposal-document-button' }}
      label="Gerar documento"
      kind={kind}
      disabled={loadingPdf || disabled}
      loading={loadingPdf}
      onClick={generateNewDocumentHandler}
      size={isButtonSmallSize ? 'small' : 'medium'}
    />
  );
};

export default CreateProposalDocumentButton;
