import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import useProductOne from '@hooks/use-product-one';
import useWhiteLabel from '@hooks/use-white-label';
import { parseProductOneSimulationResults } from '@contexts/product-one/parser';

import { applyWhiteLabelColors } from '@components/atoms/new-proposal/icon-card/content/helper';
import SimplifiedProposalSectionOne from '@components/organisms/simplified-proposal/simplified-proposal-section-one';
import SimplifiedProposalSectionTwo from '@components/organisms/simplified-proposal/simplified-proposal-section-two';
import SimplifiedProposalSectionThree from '@components/organisms/simplified-proposal/simplified-proposal-section-three';
import SimplifiedProposalAnnualBreakdown from '@components/molecules/simplified-proposal/simplified-proposal-annual-breakdown';
import { getAnnualBreakdownDataListProductOne } from '@components/organisms/simplified-proposal/helper';
import LoadingPage from '@components/templates/loading-page';
import ErrorUpdateTheProposal from '@pages/error-update-the-proposal';

const ProductOneSimplifiedProposal = () => {
  const { id } = useParams<{ id: string }>();
  const { whiteLabelSupplier } = useWhiteLabel();

  const { getProductOneProposalById, proposal, isValidProposalProductOne, isLoadingProductOne } = useProductOne();
  const simulationResults = proposal && parseProductOneSimulationResults(proposal);
  const firstResult = simulationResults && simulationResults[0];
  const targetYear = proposal?.calculatorMetadata?.firstResult?.targetYearGroup;
  const annualBreakdownDataList = proposal && getAnnualBreakdownDataListProductOne(proposal, targetYear);

  React.useEffect(() => {
    if (id) getProductOneProposalById(id);
  }, [id]);

  useEffect(() => {
    applyWhiteLabelColors({ ...whiteLabelSupplier?.colors });
  }, [whiteLabelSupplier]);
  if (!isLoadingProductOne) {
    if (isValidProposalProductOne) {
      return (
        <div className="grid grid-cols-1 xl:grid-cols-6 bg-neutral-10 gap-7 xl:gap-9 md:p-7 xl:p-9">
          <div className="col-span-full">
            {firstResult && (
              <SimplifiedProposalSectionOne
                leadName={firstResult?.leadName as string}
                totalSaving={firstResult?.totalSaving as number}
                totalSavingByMonth={
                  firstResult?.totalSavingByMonth ? firstResult?.totalSavingByMonth : firstResult?.savingByMonth
                }
                savingPercentage={firstResult?.savingPercentage as number}
                proposalValidity={proposal?.calculatorMetadata?.inputs.proposalValidity as string}
              />
            )}
          </div>
          <div className="col-span-full">
            <SimplifiedProposalSectionTwo simulationExtendedResult={firstResult} />
          </div>
          <div className="col-span-1 md:col-span-full px-5 lg:px-0">
            {annualBreakdownDataList && annualBreakdownDataList.length > 0 && (
              <SimplifiedProposalAnnualBreakdown annualBreakdownDataList={annualBreakdownDataList} />
            )}
          </div>
          <div className="col-span-full">
            <SimplifiedProposalSectionThree />
          </div>
        </div>
      );
    } else {
      return <ErrorUpdateTheProposal />;
    }
  } else {
    return <LoadingPage />;
  }
};

export default ProductOneSimplifiedProposal;
