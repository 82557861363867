import axios from 'axios';
import config from '@src/config';

  const serverApiClient = axios.create({
    baseURL: `${config.SERVER_BASE_URL}/api/amplitude`,
    
    withCredentials: true,
  });
class EventTracker {
  private apiUrl = '/track-event';

  trackEvent(eventLabel: string, eventProperties?: Record<string, string | number>) {
    try {
      const eventData = {
        eventType: eventLabel,
        properties: eventProperties,
      };

      serverApiClient.post(this.apiUrl, eventData, {
        headers: {
        'Content-Type': 'application/json',
      },});
    } catch (error) {
      throw new Error('Error sending event to the server');
    }
  }
}

const eventTracker = new EventTracker();
export default eventTracker;
