import React from 'react';
import { withAuthenticationRequired } from '@auth0/auth0-react';

import { useAuth } from '@src/auth-wrapper';
import LoadingPage from '@components/templates/loading-page';

interface PrivateRouteProps {
  children: JSX.Element;
}

export const PrivateRoute: React.FC<PrivateRouteProps> = ({ children }) => {
  const {
    setIsPrivateRoute,
    authStatus: { accessToken },
  } = useAuth();

  setIsPrivateRoute(true);
  if (accessToken) {
    return children;
  } else return <LoadingPage></LoadingPage>;
};
