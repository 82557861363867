import { IErrorData } from '@utils/error-handling';

import LeadProposalsHubFooter, { IProposalPreviewFooter } from '../general/lead-proposals-hub/footer';
import ErrorMarquee from '../general/simulation/error-marquee';

interface ILeadProposalsHubBodyWrapper {
  children: JSX.Element;
  simulationPreviewFooterProps: IProposalPreviewFooter;
  isProposalComplete?: boolean;
  errorData?: IErrorData;
  inputs?: Record<string, any>;
}

const LeadProposalsHubBodyWrapper = ({
  children,
  simulationPreviewFooterProps,
  isProposalComplete = false,
  errorData,
}: ILeadProposalsHubBodyWrapper): JSX.Element => {
  return (
    <div className="flex flex-col justify-start">
      <div className="mb-6 mt-3">
        {isProposalComplete ? (
          <>{children}</>
        ) : (
          <ErrorMarquee errorData={errorData} leadName={simulationPreviewFooterProps.leadName} />
        )}
      </div>
      <LeadProposalsHubFooter
        {...simulationPreviewFooterProps}
        isProposalComplete={isProposalComplete}
        errorData={errorData}
      />
    </div>
  );
};

export default LeadProposalsHubBodyWrapper;
