import React from 'react';
import { FieldValues, UseFormTrigger } from 'react-hook-form';

import { ISimulationPayloadBase } from '@schemas/types';
import { UserInfo } from '@contexts/users/types';
import { COMPANY_TYPES, ENERGY_DISTRIBUTION_COMPANIES, TARIFFS_INDEXER } from '@utils/constants';

import { fieldVerificator } from '@components/molecules/form/simulator/helper';
import { GenericFormField } from '@components/atoms/form/fields/generic-form-field';
import { SelectFormField } from '@components/atoms/form/fields/select-form-field';
import { NumericFormField } from '@components/atoms/form/fields/numeric-form-field';
import { SimulatorFormTooltipsContentIndexer as tooltipsContent } from '@components/molecules/form/simulator/static-data';
import { CheckboxFormField } from '../fields/checkbox-form-field';

export function BuildManagementSimulatorFields({
  unitNum,
  fieldsController,
  isAlredyAtAcl,
  setIsAlredyAtACL,
  trigger,
  user,
}: {
  unitNum: number;
  fieldsController: Record<string, boolean> | undefined;
  isAlredyAtAcl: boolean;
  setIsAlredyAtACL: React.Dispatch<React.SetStateAction<boolean>>;
  trigger: UseFormTrigger<FieldValues>;
  user: UserInfo | undefined;
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full grid grid-cols-1 lg:grid-cols-3 gap-y-6 gap-x-7">
        <GenericFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.nickname`}
          field={`units.${unitNum}.nickname`}
          label="Nome"
          placeholder="Unidade Urca"
          options={{ required: { value: true, message: 'O nome da unidade é obrigatório' } }}
          className="col-span-1 lg:col-span-2"
        />
        <CheckboxFormField<ISimulationPayloadBase>
          label=""
          field={`units.${unitNum}.isAlredyAtAcl`}
          options={{
            onChange: () => setIsAlredyAtACL((previous) => !previous),
          }}
          inputOptions={[
            {
              id: `units.${unitNum}.isAlredyAtAcl`,
              optionLabel: 'Está no Mercado Livre',
              value: isAlredyAtAcl,
              defaultChecked: isAlredyAtAcl,
            },
          ]}
        />
        {fieldVerificator({ fieldName: 'tariff', fieldPriority: 'PRIMARY', fields: fieldsController }) && (
          <SelectFormField<ISimulationPayloadBase>
            id={`units.${unitNum}.tariff`}
            field={`units.${unitNum}.tariff`}
            label="Tarifas"
            inputOptions={Object.entries(TARIFFS_INDEXER)
              .slice(0, 4)
              .map((tariffEntry) => ({
                value: tariffEntry[0],
                optionLabel: `${tariffEntry[1].subgroup} ${tariffEntry[1].tariff_modality}`,
              }))}
            transform={{
              output: (value) => parseInt(value as string),
            }}
            options={{
              required: { value: true, message: 'Escolha uma tarifa' },
            }}
            tooltipContent={tooltipsContent.unit.tariff}
          />
        )}
        <SelectFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.icmsType`}
          field={`units.${unitNum}.icmsType`}
          label="Tipo de Empresa"
          inputOptions={[
            {
              value: 'ICMS_COMERCIAL',
              optionLabel: 'Comercial',
            },
            {
              value: 'ICMS_INDUSTRIAL',
              optionLabel: 'Industrial',
            },
          ]}
          options={{ required: { value: true, message: 'Escolha uma classificação' } }}
          tooltipContent={tooltipsContent.unit.icmsType}
        />
        <SelectFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.energyDistributionCompany`}
          field={`units.${unitNum}.energyDistributionCompany`}
          label="Distribuidora"
          inputOptions={Object.entries(ENERGY_DISTRIBUTION_COMPANIES).map((edcEntry) => ({
            value: edcEntry[0],
            optionLabel: edcEntry[1],
          }))}
          transform={{
            output: (value) => parseInt(value as string),
          }}
          options={{
            required: { value: true, message: 'Escolha uma distribuidora' },
          }}
          tooltipContent={tooltipsContent.unit.energyDistributionCompany}
        />

        <GenericFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.supplyStartDate`}
          field={`units.${unitNum}.supplyStartDate`}
          label="Data de Início de Fornecimento"
          placeholder="dd/mm/aaaa"
          options={{
            required: { value: true, message: 'Insira uma data válida' },
            onChange: () => {
              trigger(['manualPricesOrDiscountsStartYear']);
            },
          }}
          tooltipContent={tooltipsContent.unit.supplyStartDate}
        />
        {!user?.isCommercialDealer &&
          fieldVerificator({ fieldName: 'powerGeneratorCost', fieldPriority: 'PRIMARY', fields: fieldsController }) && (
            <NumericFormField<ISimulationPayloadBase>
              id={`units.${unitNum}.powerGeneratorCost`}
              field={`units.${unitNum}.powerGeneratorCost`}
              label="Custo do Gerador"
              formatProps={{
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 10,
                prefix: 'R$ ',
              }}
              tooltipContent={tooltipsContent.unit.powerGeneratorCost}
            />
          )}
      </div>
    </div>
  );
}

export function BuildRetailSimulatorFields({
  unitNum,
  fieldsController,
  isAlredyAtAcl,
  setIsAlredyAtACL,
  trigger,
  user,
  isLowTension,
}: {
  unitNum: number;
  fieldsController: Record<string, boolean> | undefined;
  trigger: any;
  user: any;
  isLowTension: boolean;
  isAlredyAtAcl: boolean;
  setIsAlredyAtACL: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  return (
    <div className="flex flex-col gap-4">
      <div className="w-full grid grid-cols-2 lg:grid-cols-2 gap-y-6 gap-x-7">
        <GenericFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.nickname`}
          field={`units.${unitNum}.nickname`}
          label="Nome"
          placeholder="Unidade Urca"
          options={{ required: { value: true, message: 'O nome da unidade é obrigatório' } }}
          className="col-span-1"
        />
        <CheckboxFormField<ISimulationPayloadBase>
          label=""
          field={`units.${unitNum}.isAlredyAtAcl`}
          options={{
            onChange: () => setIsAlredyAtACL((previous) => !previous),
          }}
          inputOptions={[
            {
              id: `units.${unitNum}.isAlredyAtAcl`,
              optionLabel: 'Está no Mercado Livre',
              value: isAlredyAtAcl,
              defaultChecked: isAlredyAtAcl,
            },
          ]}
        />
        <GenericFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.supplyStartDate`}
          field={`units.${unitNum}.supplyStartDate`}
          label="Data de Início de Fornecimento"
          placeholder="dd/mm/aaaa"
          options={{
            required: { value: true, message: 'Insira uma data válida' },
            onChange: () => {
              trigger(['manualPricesOrDiscountsStartYear']);
            },
          }}
          tooltipContent={tooltipsContent.unit.supplyStartDate}
        />
        <SelectFormField<ISimulationPayloadBase>
          id={`units.${unitNum}.energyDistributionCompany`}
          field={`units.${unitNum}.energyDistributionCompany`}
          label="Distribuidora"
          inputOptions={Object.entries(ENERGY_DISTRIBUTION_COMPANIES).map((edcEntry) => ({
            value: edcEntry[0],
            optionLabel: edcEntry[1],
          }))}
          transform={{
            output: (value) => parseInt(value as string),
          }}
          options={{
            required: { value: true, message: 'Escolha uma distribuidora' },
          }}
          tooltipContent={tooltipsContent.unit.energyDistributionCompany}
        />
        {!user?.isCommercialDealer &&
          fieldVerificator({ fieldName: 'powerGeneratorCost', fieldPriority: 'PRIMARY', fields: fieldsController }) && (
            <NumericFormField<ISimulationPayloadBase>
              id={`units.${unitNum}.powerGeneratorCost`}
              field={`units.${unitNum}.powerGeneratorCost`}
              label="Custo do Gerador"
              formatProps={{
                mask: '_',
                allowNegative: false,
                fixedDecimalScale: false,
                decimalSeparator: ',',
                thousandSeparator: '.',
                decimalScale: 10,
                prefix: 'R$ ',
              }}
              tooltipContent={tooltipsContent.unit.powerGeneratorCost}
              className="col-span-1"
            />
          )}

        <h2 className="col-span-2 font-bold">Modalidade tarifária</h2>
        {fieldVerificator({ fieldName: 'tariff', fieldPriority: 'PRIMARY', fields: fieldsController }) && (
          <SelectFormField<ISimulationPayloadBase>
            id={`units.${unitNum}.tariff`}
            field={`units.${unitNum}.tariff`}
            label="Tarifas"
            inputOptions={Object.entries(TARIFFS_INDEXER).map((tariffEntry) => ({
              value: tariffEntry[0],
              optionLabel: `${tariffEntry[1].subgroup} ${tariffEntry[1].tariff_modality}`,
            }))}
            transform={{
              output: (value) => parseInt(value as string),
            }}
            options={{
              required: { value: true, message: 'Escolha uma tarifa' },
            }}
            tooltipContent={tooltipsContent.unit.tariff}
            className="col-span-1"
          />
        )}
        <div className="col-span-2 grid grid-cols-1  lg:grid-cols-2 gap-y-6 gap-x-7">
          <SelectFormField<ISimulationPayloadBase>
            id={`units.${unitNum}.icmsType`}
            field={`units.${unitNum}.icmsType`}
            label="Tipo de Empresa"
            inputOptions={[
              {
                value: 'ICMS_COMERCIAL',
                optionLabel: 'Comercial',
              },
              {
                value: 'ICMS_INDUSTRIAL',
                optionLabel: 'Industrial',
              },
            ]}
            options={{ required: { value: true, message: 'Escolha uma classificação' } }}
            tooltipContent={tooltipsContent.unit.icmsType}
            className="col-span-1"
          />
          {!user?.isCommercialDealer &&
            isLowTension &&
            fieldVerificator({ fieldName: 'companyType', fieldPriority: 'SECONDARY', fields: fieldsController }) && (
              <SelectFormField<ISimulationPayloadBase>
                id={`units.${unitNum}.companyType`}
                field={`units.${unitNum}.companyType`}
                label="Classificação da Empresa"
                inputOptions={Object.entries(COMPANY_TYPES).map((tariffEntry) => ({
                  value: tariffEntry[0],
                  optionLabel: tariffEntry[1],
                }))}
                transform={{
                  output: (value) => parseInt(value as string),
                }}
                options={{ required: { value: isLowTension, message: 'Escolha um tipo' } }}
                tooltipContent={tooltipsContent.unit.companyType}
                className="col-span-1"
              />
            )}
        </div>
      </div>
    </div>
  );
}
