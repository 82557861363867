import React from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { useAuth } from '@src/auth-wrapper';
import { CheckboxFormField } from '@components/atoms/form/fields/checkbox-form-field';
import { ISimulationPayloadBase } from '@schemas/types';

export function SimulatiorFormEnergyTypeComparisonSection<T>() {
  const { setValue, watch } = useFormContext();
  const aux__useManualAclPrice = watch('aux__useManualAclPrice');
  const aux__useEnergyTypeComparison = useWatch<ISimulationPayloadBase>({
    name: 'aux__useEnergyTypeComparison',
  });
  const { user } = useAuth();

  const comparisonSectionHandler = () => {
    setValue('aux__useEnergyTypeComparison', !aux__useEnergyTypeComparison);
    return;
  };
  React.useEffect(() => {
    if (aux__useManualAclPrice === true) {
      setValue('aux__useEnergyTypeComparison', false);
    }
  }, [aux__useManualAclPrice]);

  return (
    <>
      {!user?.isCommercialDealer && !user?.isWhiteLabel && (
        <div className="flex flex-col gap-5 bg-neutral-10 pt-5 pb-5 my-6 px-6 rounded-medium">
          <CheckboxFormField<ISimulationPayloadBase>
            data-cy="check-box-show-energy-type-comparison"
            label=""
            field="aux__useEnergyTypeComparison"
            inputOptions={[
              {
                id: 'enableEnergyTypeComparison',
                value: 'enableEnergyTypeComparison',
                optionLabel: 'Comparativo de tipos de energia',
                defaultChecked: aux__useEnergyTypeComparison,
              },
            ]}
            options={{
              onChange: comparisonSectionHandler,
              disabled: aux__useManualAclPrice ? true : false,
            }}
          />
        </div>
      )}
    </>
  );
}
