import React from 'react';
import { Button, FeedbackNotification } from '@clarke-energia/foton';
import { getErrorMessage, IErrorData } from '@utils/error-handling';

import useErrorClipboard from '@hooks/use-error-clipboard';
import SemanticMarquee from '@components/atoms/general/semantic-marquee';

interface IErrorMarquee {
  errorData?: IErrorData;
  leadName?: string;
}

const ErrorMarquee = ({ errorData, leadName }: IErrorMarquee): JSX.Element => {
  const { CopyErrorData, errorClipboardFeedbackNotificationContent } = useErrorClipboard();

  return (
    <SemanticMarquee kind="ERROR">
      <>
        <div className="flex items-center gap-6">
          <h5>{getErrorMessage(errorData)}</h5>
          <Button
            className="border-danger-60 text-danger-60 bg-white hover:bg-red-50 "
            icon="ArrowUpOnSquareIcon"
            label="  "
            size="medium"
            onClick={() => {
              CopyErrorData(errorData, leadName);
            }}
          />
        </div>
        {errorClipboardFeedbackNotificationContent && (
          <div className="fixed right-0 -bottom-12- my-6 mx-6 max-w-full lg:w-fit z-[200]">
            <FeedbackNotification {...errorClipboardFeedbackNotificationContent} />
          </div>
        )}
      </>
    </SemanticMarquee>
  );
};

export default ErrorMarquee;
