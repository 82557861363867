import { useState } from 'react';
import { FeedbackNotification } from '@clarke-energia/foton';
import { FormControl, InputLabel, MenuItem, Select, Box, SelectChangeEvent } from '@mui/material';

import { TARIFF, TARIFF_LABELS, ENERGY_DISTRIBUTION_COMPANIES } from '@utils/constants';
import limitedIcon from '@assets/newProposal/image (5).png';
import {
  getConsumptionLowVoltageTableData,
  getConsumptionMediumVoltageTableData,
  getCurrentMarketUnitTableData,
  getEconomyUnitTableData,
  getLowVoltageFinalTariffTableData,
  getMediumVoltageFinalTariffTableData,
  getPremissisesTableData,
  getPriceListCompositionTableData,
  getPriceListTableData,
  getTargetMarketUnitTableData,
} from '@pages/detailed-analysis/helper';
import { IUnitReport } from '@pages/detailed-analysis/types';

import { HeroIconSolidOrOutline } from '@components/atoms/form/hero-icon';
import ButtonComponent from '@components/atoms/form/button';
import { TableWithMultiplesValues } from '@components/atoms/detailedAnalysisGC/table-with-multiples-values';
import { DataTable } from '@components/atoms/detailedAnalysisGC/dataTable';

import { DropdownSection } from './dropdonw';
import { JsonViewer } from './jsonPrettier';

type ICombinedUnit = {
  currentEnergyTypeUnit: IUnitReport;
  alternativeEnergyTypeUnit: IUnitReport;
};

export function GenerateTablesByUnit({
  units,
  alternativeEnergyTypeUnits,
  mainEnergyTpe,
}: {
  units: Array<IUnitReport>;
  alternativeEnergyTypeUnits?: Array<IUnitReport>;
  mainEnergyTpe: string;
}) {
  const combinedUnits = units.map((unit, index) => ({
    currentEnergyTypeUnit: unit,
    alternativeEnergyTypeUnit: alternativeEnergyTypeUnits ? alternativeEnergyTypeUnits[index] : null,
  }));

  const [selectedCombinedUnit, setSelectedCombinedUnit] = useState<ICombinedUnit | null>(null);

  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const handleSelectChange = (event: SelectChangeEvent<string>) => {
    const selectedValue = event.target.value as string;
    setSelectedCombinedUnit(
      selectedValue === 'all'
        ? null
        : combinedUnits.find((combinedUnit) => combinedUnit?.currentEnergyTypeUnit?.nickname === selectedValue) || null,
    );
  };

  const filteredCombinedUnits = selectedCombinedUnit ? [selectedCombinedUnit] : combinedUnits;
  return (
    <>
      <Box mb={2} className="w-fit">
        <FormControl fullWidth variant="outlined">
          <InputLabel id="unit-select-label">Selecione uma unidade</InputLabel>
          <Select
            labelId="unit-select-label"
            id="unit-select"
            value={selectedCombinedUnit?.currentEnergyTypeUnit?.nickname || 'all'}
            onChange={(e) => handleSelectChange(e)}
            label="Selecione uma unidade"
            renderValue={(value) =>
              value === 'all' ? 'Todas as Unidades' : selectedCombinedUnit?.currentEnergyTypeUnit?.nickname
            }
          >
            <MenuItem value="all">Todas as Unidades</MenuItem>
            {combinedUnits?.map((combinedUnit) => (
              <MenuItem
                className="flex gap-x-5"
                key={combinedUnit.currentEnergyTypeUnit?.nickname}
                value={combinedUnit.currentEnergyTypeUnit?.nickname}
              >
                {combinedUnit.currentEnergyTypeUnit?.nickname}
                {selectedCombinedUnit?.currentEnergyTypeUnit?.nickname ===
                  combinedUnit.currentEnergyTypeUnit?.nickname && (
                  <HeroIconSolidOrOutline icon="CheckIcon" className="text-primary-70 size-5 ml-2" />
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>
      {filteredCombinedUnits?.map((combinedUnit, index) => {
        const priceListCompositionTableData = getPriceListCompositionTableData(combinedUnit.currentEnergyTypeUnit);
        const priceListCompositionTableDataForAlternativeEnergyType = combinedUnit.alternativeEnergyTypeUnit
          ? getPriceListCompositionTableData(combinedUnit.alternativeEnergyTypeUnit)
          : null;
        const currentMarketUnitTableData = getCurrentMarketUnitTableData(combinedUnit.currentEnergyTypeUnit);
        const targetMarketUnitTableData = getTargetMarketUnitTableData(combinedUnit.currentEnergyTypeUnit);
        const economyUnitTableData = getEconomyUnitTableData(combinedUnit.currentEnergyTypeUnit);
        const currentMarketUnitTableDataForAlternativeEnergyType =
          combinedUnit?.alternativeEnergyTypeUnit &&
          getCurrentMarketUnitTableData(combinedUnit?.alternativeEnergyTypeUnit);
        const targetMarketUnitTableDataForAlternativeEnergyType =
          combinedUnit?.alternativeEnergyTypeUnit &&
          getTargetMarketUnitTableData(combinedUnit?.alternativeEnergyTypeUnit);
        const economyUnitTableDataForAlternativeEnergyType =
          combinedUnit?.alternativeEnergyTypeUnit && getEconomyUnitTableData(combinedUnit?.alternativeEnergyTypeUnit);
        const premissisesTableData = getPremissisesTableData(combinedUnit.currentEnergyTypeUnit);
        const lowVoltageFinalTariffTableData = getLowVoltageFinalTariffTableData(combinedUnit.currentEnergyTypeUnit);
        const mediumVoltageFinalTariffTableData = getMediumVoltageFinalTariffTableData(
          combinedUnit.currentEnergyTypeUnit,
        );
        const consumptionMediumVoltageTableData = getConsumptionMediumVoltageTableData(
          combinedUnit.currentEnergyTypeUnit,
        );
        const consumptionLowVoltageTableData =
          combinedUnit.currentEnergyTypeUnit.premises.tariff == TARIFF.B3_OPTING ||
          combinedUnit.currentEnergyTypeUnit.premises.tariff == TARIFF.B3_STANDARD
            ? getConsumptionLowVoltageTableData(combinedUnit.currentEnergyTypeUnit)
            : null;
        const priceListTableData = getPriceListTableData(combinedUnit.currentEnergyTypeUnit);

        return (
          <DropdownSection
            key={index}
            title={combinedUnit.currentEnergyTypeUnit?.premises?.nickname}
            iconName="OfficeBuildingIcon"
            iconColor="text-gray-500"
            titleBackgroundColor="primary-60"
            titleBackgroundColorOnHover="primary-70"
            className="border-[.0313rem] border-t-0  rounded-t-lg"
          >
            <DropdownSection
              title={`Composição da Lista de Preços ${mainEnergyTpe == 'INCENTIVIZED_50' ? 'i-5' : 'i-100'}`}
              iconName="CalculatorIcon"
              iconColor="text-primary-70"
              className="bg-slate-50 border-brand-gray-10 border rounded-t-lg"
              titleBackgroundColor="brand-gray-10"
              titleBackgroundColorOnHover="brand-gray-20"
            >
              <TableWithMultiplesValues {...priceListCompositionTableData} />
              <div className="w-96 h-fit flex gap-4 pt-8 px-2">
                <h3 className="text-paragraph-small">Legenda de Premissas :</h3>
                <span className="flex gap-2 justify-center items-center text-caption-medium">
                  <img src={limitedIcon} alt="limited" className="size-5" />
                  Limite de variação
                </span>
                <span className="flex gap-2 justify-center items-center text-caption-medium">
                  <HeroIconSolidOrOutline icon="CurrencyDollarIcon" className="text-black size-5" />
                  Limite de preço
                </span>
              </div>
            </DropdownSection>
            {priceListCompositionTableDataForAlternativeEnergyType && (
              <DropdownSection
                title={`Composição da Lista de Preços (Tipo de energia alternativo ${
                  mainEnergyTpe == 'INCENTIVIZED_50' ? 'i-100' : 'i-5'
                })`}
                iconName="CalculatorIcon"
                iconColor="text-primary-70"
                className="bg-slate-50 border-brand-gray-10 border rounded-t-lg"
                titleBackgroundColor="brand-gray-10"
                titleBackgroundColorOnHover="brand-gray-20"
              >
                <TableWithMultiplesValues {...priceListCompositionTableDataForAlternativeEnergyType} />
                <div className="w-96 h-fit flex gap-4 pt-8 px-2">
                  <h3 className="text-paragraph-small">Legenda de Premissas :</h3>
                  <span className="flex gap-2 justify-center items-center text-caption-medium">
                    <img src={limitedIcon} alt="limited" className="size-5" />
                    Limite de variação
                  </span>
                  <span className="flex gap-2 justify-center items-center text-caption-medium">
                    <HeroIconSolidOrOutline icon="CurrencyDollarIcon" className="text-black size-5" />
                    Limite de preço
                  </span>
                </div>
              </DropdownSection>
            )}
            <DropdownSection
              iconColor="text-primary-70"
              title="Premissas de Cálculo"
              className="bg-slate-50 border-brand-gray-10 border rounded-t-lg"
              titleBackgroundColor="brand-gray-10"
              titleBackgroundColorOnHover="brand-gray-20"
            >
              {combinedUnit.currentEnergyTypeUnit.lowVoltageFinalTariffsList && (
                <DropdownSection
                  fixedOpen={true}
                  title={`Tarifas Baixa Tensão ${
                    TARIFF_LABELS[
                      combinedUnit.currentEnergyTypeUnit.lowVoltageFinalTariffsList[0]
                        ?.tariff as unknown as keyof typeof TARIFF_LABELS
                    ]
                  } para distribuidora ${
                    ENERGY_DISTRIBUTION_COMPANIES[
                      combinedUnit.currentEnergyTypeUnit.lowVoltageFinalTariffsList[0]?.energyDistributionCompany.id
                    ]
                  }`}
                  iconName="CurrencyDollarIcon"
                  className="mb-4"
                >
                  <TableWithMultiplesValues {...lowVoltageFinalTariffTableData} />
                </DropdownSection>
              )}
              {combinedUnit.currentEnergyTypeUnit.mediumVoltageFinalTariffsList && (
                <DropdownSection
                  title={`Tarifas Média Tensão ${
                    TARIFF_LABELS[
                      combinedUnit.currentEnergyTypeUnit.mediumVoltageFinalTariffsList[0]
                        ?.tariff as unknown as keyof typeof TARIFF_LABELS
                    ]
                  } para distribuidora ${
                    ENERGY_DISTRIBUTION_COMPANIES[
                      combinedUnit.currentEnergyTypeUnit.mediumVoltageFinalTariffsList[0]?.energyDistributionCompany.id
                    ]
                  }`}
                  iconName="CurrencyDollarIcon"
                  className="bg-white rounded-t-lg"
                >
                  <TableWithMultiplesValues {...mediumVoltageFinalTariffTableData} />
                </DropdownSection>
              )}
              <DropdownSection title="Premissas" iconName="ExclamationCircleIcon" className="bg-white rounded-t-lg">
                <DataTable data={premissisesTableData} />
              </DropdownSection>

              <DropdownSection
                title="Consumo Média Tensão"
                iconName="LightningBoltIcon"
                className="bg-white rounded-t-lg"
              >
                <DataTable data={consumptionMediumVoltageTableData} />
              </DropdownSection>

              {consumptionLowVoltageTableData && (
                <DropdownSection
                  title="Consumo Baixa Tensão"
                  iconName="LightningBoltIcon"
                  className="bg-white rounded-t-lg"
                >
                  <DataTable data={consumptionLowVoltageTableData} />
                </DropdownSection>
              )}

              <DropdownSection title="Lista de Preços" iconName="CurrencyDollarIcon" className="bg-white">
                <DataTable data={priceListTableData} formatString="$ 0,0.00" />
              </DropdownSection>
            </DropdownSection>
            <DropdownSection
              iconColor="text-primary-70"
              title={`Resultado de Simulação (${mainEnergyTpe == 'INCENTIVIZED_50' ? 'i-5' : 'i-100'}) - ${
                combinedUnit.currentEnergyTypeUnit?.sampleMonthSimulation?.report?.monthLabel
              }/${combinedUnit.currentEnergyTypeUnit?.sampleMonthSimulation?.report?.year}`}
              className="bg-slate-50 border-brand-gray-10 border rounded-t-lg"
              titleBackgroundColor="brand-gray-10"
              titleBackgroundColorOnHover="brand-gray-20"
            >
              <DropdownSection
                title="Dados do Mercado Cativo"
                iconName="PresentationChartLineIcon"
                className="bg-white rounded-t-lg"
              >
                <DataTable data={currentMarketUnitTableData} />
              </DropdownSection>

              <DropdownSection
                title="Dados do Mercado Livre"
                iconName="PresentationChartBarIcon"
                className="bg-white rounded-t-lg"
              >
                <DataTable data={targetMarketUnitTableData} />
              </DropdownSection>

              <DropdownSection title="Dados de Economia" iconName="CalculatorIcon" className="bg-white rounded-t-lg">
                <DataTable data={economyUnitTableData} />
              </DropdownSection>
            </DropdownSection>
            {combinedUnit?.alternativeEnergyTypeUnit?.sampleMonthSimulation &&
              currentMarketUnitTableDataForAlternativeEnergyType &&
              targetMarketUnitTableDataForAlternativeEnergyType &&
              economyUnitTableDataForAlternativeEnergyType && (
                <DropdownSection
                  iconColor="text-primary-70"
                  title={`Resultado de Simulação (Tipo alternativo de energia ${
                    mainEnergyTpe == 'INCENTIVIZED_50' ? 'i-100' : 'i-5'
                  }) - ${combinedUnit.alternativeEnergyTypeUnit?.sampleMonthSimulation?.report?.monthLabel}/${
                    combinedUnit.alternativeEnergyTypeUnit?.sampleMonthSimulation?.report?.year
                  }`}
                  className="bg-slate-50 border-brand-gray-10 border rounded-t-lg"
                  titleBackgroundColor="brand-gray-10"
                  titleBackgroundColorOnHover="brand-gray-20"
                >
                  <DropdownSection
                    title="Dados do Mercado Cativo"
                    iconName="PresentationChartLineIcon"
                    className="bg-white rounded-t-lg"
                  >
                    <DataTable data={currentMarketUnitTableDataForAlternativeEnergyType} />
                  </DropdownSection>

                  <DropdownSection
                    title="Dados do Mercado Livre"
                    iconName="PresentationChartBarIcon"
                    className="bg-white rounded-t-lg"
                  >
                    <DataTable data={targetMarketUnitTableDataForAlternativeEnergyType} />
                  </DropdownSection>

                  <DropdownSection
                    title="Dados de Economia"
                    iconName="CalculatorIcon"
                    className="bg-white rounded-t-lg"
                  >
                    <DataTable data={economyUnitTableDataForAlternativeEnergyType} />
                  </DropdownSection>
                </DropdownSection>
              )}
            <DropdownSection
              title="Dados Brutos"
              className="bg-slate-50"
              iconName="DatabaseIcon"
              iconColor="text-primary-70"
            >
              <div className="flex flex-col gap-8 items-end justify-end">
                <ButtonComponent
                  label="Copie os dados brutos"
                  kind="primary"
                  icon="ClipboardCopyIcon"
                  iconPosition="right"
                  size={'3'}
                  className="text-heading-small text-brand-gray-60  rounded-lg"
                  onClick={() => {
                    navigator.clipboard.writeText(JSON.stringify(filteredCombinedUnits[0]));
                    setFeedbackOpen(true);
                  }}
                />
                {feedbackOpen && (
                  <FeedbackNotification
                    className="w-fit absolute right-0 -bottom-5"
                    message={'Dados brutos copiados para a área de transferência.'}
                    kind={'primary'}
                    label={''}
                    onCloseNotification={function (): void {
                      setFeedbackOpen(false);
                    }}
                  />
                )}
                <JsonViewer className="overflow-auto w-fit" jsonData={filteredCombinedUnits[0].currentEnergyTypeUnit} />
              </div>
            </DropdownSection>
          </DropdownSection>
        );
      })}
    </>
  );
}

export default GenerateTablesByUnit;
