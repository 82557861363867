import React from 'react';
import { useFormContext } from 'react-hook-form';

import { ISimulationResultBase } from '@schemas/types';

import { CheckboxFormField } from '@components/atoms/form/fields/checkbox-form-field';

const SimulatorFormIRecSection = (): JSX.Element => {
  const { watch } = useFormContext<ISimulationResultBase>();
  const useIRecCertificationValue = watch('useIRecCertification');

  return (
    <div className="bg-neutral-10 mt-6 pt-4 pb-6 px-6 rounded-medium ">
      <CheckboxFormField<ISimulationResultBase>
        label=""
        field="useIRecCertification"
        inputOptions={[
          {
            id: 'useIRecCertification',
            optionLabel: 'Ativar certificado I-REC',
            value: 'useIRecCertification',
            defaultChecked: useIRecCertificationValue,
          },
        ]}
      />
    </div>
  );
};

export default SimulatorFormIRecSection;
