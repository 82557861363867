import React from 'react';
import axios from 'axios';
import LegendContainer from '@components/atoms/new-proposal/legend-container';
import SlideContainer from '@components/molecules/general/simulation/slide-container.tsx';
import config from '@config';

const extractIdURL = (driveUrl: string) => {
  const match = driveUrl.match(/\/d\/([a-zA-Z0-9_-]+)\//);
  if (match && match[1]) {
    const fileId = match[1];
    return `https://drive.google.com/thumbnail?id=${fileId}&sz=w1000`;
  }
};

function FeasibilitySimulationCharts() {
  const [imagePropoposalWithDirectLink, setImageProposalWithDirectLink] = React.useState<string | undefined>();

  React.useEffect(() => {
    async function fetchData() {
      try {
        const currentDomain = window.location.origin;
        const serverPort = config.SERVER_PORT ?? '5000';
        const serverBaseURL = `${currentDomain.replace(
          /:\d+/,
          `${config.IS_IN_PRODUCTION === 'true' ? '' : `:${serverPort}`}`,
        )}/api/auth`;

        const serverApiClient = axios.create({
          baseURL: serverBaseURL,
          withCredentials: true,
        });

        const { data } = await serverApiClient.get('/request-vite-env');

        const imageProposalHistoricPrice = data.VITE_PROPOSALS_HISTORIC_PRICES_CHART_IMAGE_URL;

        setImageProposalWithDirectLink(extractIdURL(imageProposalHistoricPrice));
      } catch {}
    }

    fetchData();
  }, []);

  if (imagePropoposalWithDirectLink)
    return (
      <SlideContainer className="h-fit">
        <section className="flex flex-col gap-10 sm:gap-6 w-full h-fit min-h-full p-8">
          <LegendContainer legend="Simulação de viabilidade" />
          <div className="w-full flex flex-col sm:gap-6  items-start sm:items-center ">
            <b className="sm:w-10/12 text-heading-xsmall text-primary-70 xl:text-heading-xlarge mt-2">
              O papel da Clarke é assessorar o seu grupo para encontrar as melhores oportunidades de compra de energia
            </b>
          </div>
          <div className="w-full flex justify-center sm:py-5 ">
            <div className="min-w-full min-h-fit flex justify-center items-center">
              <img src={imagePropoposalWithDirectLink} alt="Gráfico de simulação de viabilidade" />
            </div>
          </div>
        </section>
      </SlideContainer>
    );
  return null;
}

export default FeasibilitySimulationCharts;
