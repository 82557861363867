import * as z from 'zod';
import {
  CustomFeeSchema,
  ProposalSchema,
  SimulationExtendedResultBaseSchema,
  simulationPayloadBaseSchema,
  simulationReportBaseSchema,
  SimulationResultBaseSchema,
} from '@schemas';
import { validateProductTwoSimulatorInputs } from '@schemas/validations';
import { getMigrationStartYear } from '@components/molecules/form/simulator/sections/simulator-form-financial-projection-section';

export const ProductTwoRetailTraderPriceSchema = z.object({
  id: z.string(),
  prices: z.record(z.number()),
  retailTraderId: z.string(),
  expirationDate: z.string(),
});

export const ProductTwoSimulatorSchema = z.object({
  customFee: CustomFeeSchema.nullable().optional(),
  targetYear: z.number(),
  validPrices: z.boolean().nullable().optional(),
  customTaxes: z.record(z.any()).nullable().optional(),
  state: z.string().nullable().optional(),
  retailTradersPrices: z.array(ProductTwoRetailTraderPriceSchema).nullable().optional(),
});

export const ProductTwoSimulationUnitExtendedResultSchema = simulationReportBaseSchema
  .merge(SimulationExtendedResultBaseSchema)
  .extend({
    premises: z.array(
      z.object({
        title: z.string(),
        value: z.union([z.string(), z.number()]).nullable().optional(),
      }),
    ),
  });

export const ProductTwoSimulatorInputsSchema = ProductTwoSimulatorSchema.merge(simulationPayloadBaseSchema)
  .transform((data) => {
    const { targetYear, units, customFee } = data;
    const startYear = (units && getMigrationStartYear(units)) as number;

    const simulationYears = targetYear ?? 3;
    const years = Array.from({ length: simulationYears }, (_, index) => Number(startYear) + index);
    if (customFee) {
      const customFeeValues = Object.fromEntries(
        years.map((year: number | string) => {
          // @ts-ignore
          return [`year${year}`, customFee.values?.[year] ?? customFee.values?.[`year${year}`]];
        }),
      );

      return {
        ...data,
        customFee: {
          ...customFee,
          values: customFeeValues,
        },
      };
    }
    return data;
  })
  .superRefine((data, ctx) => {
    validateProductTwoSimulatorInputs(data, ctx);
  });

export const ProductTwoSchema = z.object({
  status: z.string(),
  targetCalculationsNumber: z.number(),
});

export const ProductTwoSimulationScenarioSchema = z.object({
  bestRetailTraderResult: z.record(z.string(), z.any()).nullable(),
  retailTradersResult: z.array(z.record(z.string(), z.any())),
});

export const ProductTwoBestSimulationResultSchema = z.object({
  calculatorMetadata: z.record(z.string(), z.any()).nullable(),
  createdAt: z.string(),
  id: z.string(),
  inputs: ProductTwoSimulatorInputsSchema,
  pricesExpirationDate: z.string().nullable().optional(),
  retailTraderId: z.string(),
  scenario: z.string(),
  simulationPProposalId: z.string(),
});

export const EnergyTypeComparisonItemSchema = z.object({
  totalSaving: z.number(),
  totalSavingPercentage: z.number(),
  rankingPosition: z.number(),
});

export const AlternativePriceListCompositionSchema = z.object({
  unitName: z.string(),
  alternativePriceListCompositionData: z.array(z.record(z.string(), z.any())),
});

export const ProductTwoEnergyTypeComparisonSchema = z.object({
  incentivized50: EnergyTypeComparisonItemSchema,
  incentivized100: EnergyTypeComparisonItemSchema,
  alternativePriceListComposition: z.array(z.record(z.string(), z.any())),
  alternativeEnergyType: z.string(),
});

export const ProductTwoSimulationSchema = z.object({
  payback: z.number().nullable().optional(),
  unitsReports: z.array(z.record(z.string(), z.any())),
  energyTypeComparison: ProductTwoEnergyTypeComparisonSchema,
});
export const ProductTwoSimulationResultSchema = ProductTwoSimulationSchema.merge(SimulationResultBaseSchema);

export const ProductTwoExtendedResultBaseSchema = z.object({
  validPrices: z.boolean().nullable().optional(),
  premises: z.array(z.record(z.string(), z.any())),
  retailTraderId: z.string(),
});

export const ProductTwoSimulationExtendedResultBaseSchema = ProductTwoExtendedResultBaseSchema.merge(
  SimulationExtendedResultBaseSchema,
);

// @ts-expect-error - merge with superRefine in ProductTwoSimulatorInputsSchema
export const ProposalBaseSchema = ProposalSchema.merge(ProductTwoSimulatorInputsSchema).merge(
  ProductTwoSimulationScenarioSchema,
);
export const ProductTwoProposalSchema = ProductTwoSchema.merge(ProposalBaseSchema);
export const ProductTwoExtendedResultSchema = z.object({
  units: z.array(z.record(z.string(), z.any())),
});

export const ProductTwoSimulationExtendedResultSchema = ProductTwoExtendedResultSchema.merge(
  ProductTwoSimulationExtendedResultBaseSchema,
).merge(ProductTwoSimulationResultSchema);

export const ProductTwoSimulationResultSummarySchema = z.object({
  retailTraderId: z.string(),
  totalSaving: z.number(),
  totalSavingInPercentage: z.number(),
  pricesExpirationDate: z.string().nullable().optional(),
  score: z.number().nullable().optional(),
});

export const ProductTwoResultSummaryWithFixedTraderDataSchema = z.object({
  traderLogo: z.string(),
  cceeDuty: z.string(),
  paymentTerms: z.string(),
  penalty: z.string(),
  IREC: z.string(),
});

export const ProductTwoSimulationResultSummaryWithFixedTraderDataSchema =
  ProductTwoResultSummaryWithFixedTraderDataSchema.merge(ProductTwoSimulationResultSummarySchema);

export const FreeMarketBenefitSchema = z.object({
  illustration: z.string(),
  content: z.any(),
});
