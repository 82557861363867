import React from 'react';
import useWhiteLabel from '@hooks/use-white-label';
import { formatNumber } from '@utils/numeral';

enum EnergyEfficiencyAnalyzerHeaders {
  UnitConsumption = 'Unidade consumidora',
  EconomyPercent = '% de economia',
  TotalEconomy = 'Economia total',
  NetMonthlyEconomy = 'Economia líquida mensal',
}
export interface IDataUnitsSaving {
  nickname: string;
  totalSavingInPercentage: number;
  totalSaving: number;
  netMonthlyEconomy: number;
}
export interface IUnitsSaving {
  unitsSaving: Array<IDataUnitsSaving>;
}

function UnitsSavingTable({ unitsSaving }: IUnitsSaving) {
  const isPrintMode = window.location.search.includes('print');
  const hasExceededPDFLimit = unitsSaving?.length > 8;
  const shouldApplyPDFStyles = isPrintMode && hasExceededPDFLimit;

  const { isWhiteLabelProposal, whiteLabelSupplier } = useWhiteLabel();
  return (
    <table className={`table-auto ${shouldApplyPDFStyles ? 'w-full' : 'w-10/12'}`}>
      <thead>
        <tr className={`${shouldApplyPDFStyles && 'max-h-4'}`}>
          {Object.values(EnergyEfficiencyAnalyzerHeaders)?.map((header, index) => (
            <th
              key={index}
              className={`w-20 ${
                shouldApplyPDFStyles ? 'sm:max-h-10 text-paragraph-small ' : 'h-14 text-paragraph-large'
              } text-start  pr-5`}
            >
              {header}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {unitsSaving?.map((item, index) => (
          <tr className="border-t-2 h-fit" key={index}>
            <td
              className={`min-w-10 sm:min-w-20 ${
                shouldApplyPDFStyles ? 'sm:w-32 h-8' : 'sm:w-60 h-14'
              } text-start text-paragraph-small pr-3`}
            >
              {item.nickname}
            </td>
            <td
              style={{
                color: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.main : '#00DF7C',
              }}
              className={`min-w-10 sm:min-w-20 ${
                shouldApplyPDFStyles ? 'sm:w-32 h-8' : 'sm:w-60 h-14'
              } sm:h-6 text-start text-paragraph-small font-bold pr-3`}
            >
              {formatNumber(item.totalSavingInPercentage, '0%')}
            </td>
            <td
              style={{
                color: isWhiteLabelProposal ? whiteLabelSupplier?.colors?.main : '#00DF7C',
              }}
              className={`min-w-10 sm:min-w-20 ${
                shouldApplyPDFStyles ? 'sm:w-32 h-8' : 'sm:w-60 h-14'
              } sm:h-6 text-start text-paragraph-small pr-3`}
            >
              {formatNumber(item.totalSaving, '$ 0,0.00')}
            </td>
            <td
              className={`min-w-10 sm:min-w-20 ${
                shouldApplyPDFStyles ? 'sm:w-32 h-8' : 'sm:w-60 h-14'
              } sm:h-6 text-start text-paragraph-small pr-3`}
            >
              {formatNumber(item.netMonthlyEconomy, '$ 0,0.00')}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

export default UnitsSavingTable;
