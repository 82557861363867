import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';

import { IContractedDemandContext, IContractedDemandSimulatorInputs } from '../../schemas/contracted-demand/types';
import { parseContractedDemandSimulationPayload } from './parser';
import config from '@config';

export const ContractedDemandContext = createContext({} as IContractedDemandContext);

interface Provider {
  children: React.ReactNode;
}

const ContractedDemandProvider: React.FC<Provider> = ({ children }) => {
  const currentDomain = window.location.origin;

  const serverPort = config.SERVER_PORT ?? '5000';
  const serverBaseURL = `${currentDomain.replace(
    /:\d+/,
    `${config.IS_IN_PRODUCTION === 'true' ? '' : `:${serverPort}`}`,
  )}/api/contracted-demand`;

  const serverApiClient = axios.create({
    baseURL: serverBaseURL,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  function createContractedDemandSimulation(simulationData: IContractedDemandSimulatorInputs): AxiosPromise {
    const simulationPayload = parseContractedDemandSimulationPayload(simulationData);
    return serverApiClient.post('/simulation', simulationPayload);
  }

  return (
    <ContractedDemandContext.Provider
      value={{
        createContractedDemandSimulation,
      }}
    >
      {children}
    </ContractedDemandContext.Provider>
  );
};

export default ContractedDemandProvider;
