import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';
import { ISalesContext } from './types';
import config from '@config';

export const SalesContext = createContext({} as ISalesContext);

interface Provider {
  children: React.ReactNode;
}

const SalesProvider: React.FC<Provider> = ({ children }) => {
  const currentDomain = window.location.origin;

  const serverPort = config.SERVER_PORT ?? '5000';
  const serverBaseURL = `${currentDomain.replace(
    /:\d+/,
    `${config.IS_IN_PRODUCTION === 'true' ? '' : `:${serverPort}`}`,
  )}/api/sales/convert-html-to-pdf`;

  const serverApiClient = axios.create({
    baseURL: serverBaseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  function convertHtmlToPdfHandler(
    url: string,
    filename: string,
    width?: number,
    height?: number,
    apiVersion = 'v2',
  ): AxiosPromise {
    const cleanFilename = filename.replace(/[&]/g, '_e_');
    return serverApiClient.get('/', {
      params: {
        url: `/api/${apiVersion}/html-to-pdf?url=${url}&filename=${cleanFilename}${width ? `&width=${width}` : ''}${
          height ? `&height=${height}` : ''
        }`,
      },
    });
  }

  return (
    <SalesContext.Provider
      value={{
        convertHtmlToPdfHandler,
      }}
    >
      {children}
    </SalesContext.Provider>
  );
};

export default SalesProvider;
