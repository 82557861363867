import axios from 'axios';
import React from 'react';
import { FeedbackNotificationProps } from '@clarke-energia/foton';
import config from '@config';

export const getCommercialDealerComissionFeedbackNotificationContents = (
  onCloseNotification: () => void,
): Record<string, FeedbackNotificationProps> => {
  return {
    CHANGE_COMMERCIAL_DEALER_COMISSION_SUCCESS: {
      kind: 'primary',
      label: 'Sucesso',
      message: 'A forma de pagamento da comissão foi atualizada.',
      onCloseNotification,
    },
    CHANGE_COMMERCIAL_DEALER_COMISSION_FAILURE: {
      kind: 'error',
      label: 'Erro',
      message: 'Não foi possível alterar a forma de pagamento da comissão.',
      onCloseNotification,
    },
  };
};

export const calculateInInstallmentsAmountUpdate = (
  initialAmount: number,
  cashCommission: number,
  totalInstallmentWithInterest?: number,
) => {
  const [interestFactorForInstallmentsCommission, setInterestFactorForInstallmentsCommission] =
    React.useState<number>(0);
  React.useEffect(() => {
    const fetchData = async () => {
      const currentDomain = window.location.origin;
      const serverPort = config.SERVER_PORT ?? '5000';
      const serverBaseURL = `${currentDomain.replace(
        /:\d+/,
        `${config.IS_IN_PRODUCTION === 'true' ? '' : `:${serverPort}`}`,
      )}/api/auth`;

      const serverApiClient = axios.create({
        baseURL: serverBaseURL,
        withCredentials: true,
      });
      const { data } = await serverApiClient.get('/request-vite-env');
      setInterestFactorForInstallmentsCommission(data.INTEREST_FACTOR_FOR_INSTALLMENTS_COMMISSION);
    };
    fetchData();
  }, []);

  const nonCashComissionAmount = initialAmount - cashCommission;
  const inInstallmentsAmountWithInterest = nonCashComissionAmount * interestFactorForInstallmentsCommission;

  if (totalInstallmentWithInterest) {
    const interestAmount = Math.round(totalInstallmentWithInterest) - Math.round(initialAmount);
    return interestAmount < 0 ? 0 : interestAmount;
  }
  return inInstallmentsAmountWithInterest < 0 ? 0 : inInstallmentsAmountWithInterest;
};
