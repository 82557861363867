import React, { useEffect, useState, useCallback } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

import { ISimulationCompetitorPayloadBase, ISimulationUnitPayloadBase } from '@schemas/types';
import { ISimulatorFormCompetitorsListItem } from '@components/molecules/form/simulator/sections/simulator-form-competitors-list-section/simulator-form-competitor-section';

import { NumericFormField } from '../fields/numeric-form-field';
import { getMigrationStartYear } from '@components/molecules/form/simulator/sections/simulator-form-financial-projection-section';

export function SimulatorCompetitorEnergyPriceSubsection({ competitorIndex }: ISimulatorFormCompetitorsListItem) {
  const { setValue, watch } = useFormContext();
  const units = useWatch({ name: 'units' }) as ISimulationUnitPayloadBase[];

  const getTargetYear = useCallback(() => {
    const uniqueTargetYear = watch('targetYear');
    const multipleTargetYears = watch('targetYears');
    return uniqueTargetYear ?? Math.max(...(multipleTargetYears || []), 3);
  }, [watch]);

  const targetYear = getTargetYear();
  const baseFieldName = `competitorsList.${competitorIndex}.aclPrices`;
  const migrationYear = Number(getMigrationStartYear(units));

  const getInitialPrices = useCallback(() => {
    const startYear = getMigrationStartYear(units) as number;
    if (isNaN(startYear)) return {};

    return Array.from({ length: targetYear }, (_, i) => ({
      [startYear + i]: watch(`${baseFieldName}.${startYear + i}`),
    })).reduce((acc, val) => ({ ...acc, ...val }), {});
  }, [watch, units, targetYear, baseFieldName]);

  const handleOnChange = (year: string, value: number) => {
    setValue(`${baseFieldName}.${year.includes('year') ? year : `year${year}`}`, value);
  };

  return (
    <div className="flex flex-col gap-1 bg-neutral-10 pt-4 pb-6 px-6 rounded-medium">
      <h1 className="text-paragraph-medium">Preço da energia</h1>
      <div className="flex flex-row justify-start gap-6">
        {[...Array(targetYear).keys()].map((yearOffset) => {
          const currentYear = migrationYear + yearOffset;
          if (isNaN(currentYear)) return null;
          return (
            <div
              className="w-[114px]"
              key={`${baseFieldName}-${currentYear.toString().includes('year') ? currentYear : `year${currentYear}`}`}
            >
              <NumericFormField<ISimulationCompetitorPayloadBase>
                id={`${baseFieldName}.${currentYear.toString().includes('year') ? currentYear : `year${currentYear}`}`}
                field={
                  `${baseFieldName}.${
                    currentYear.toString().includes('year') ? currentYear : `year${currentYear}`
                  }` as `aclPrices.${string}`
                }
                label={currentYear.toString()}
                formatProps={{
                  mask: '_',
                  allowNegative: false,
                  fixedDecimalScale: false,
                  decimalSeparator: ',',
                  thousandSeparator: '.',
                  decimalScale: 10,
                  prefix: 'R$ ',
                }}
                options={{
                  onChange: (value) => handleOnChange(currentYear.toString(), value as number),
                  required: { value: true, message: 'Insira um valor' },
                }}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
