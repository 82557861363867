import { useContextSelector } from 'use-context-selector';
import { ErrorClipboardContext } from '@contexts/error-clipboard/index';
import { IErrorClipboardContext } from '@contexts/error-clipboard/types';

const useErrorClipboard = (): IErrorClipboardContext => {
  const CopyErrorData = useContextSelector(ErrorClipboardContext, (errorClipboard) => errorClipboard.CopyErrorData);
  const errorClipboardFeedbackNotificationContent = useContextSelector(
    ErrorClipboardContext,
    (errorClipboard) => errorClipboard.errorClipboardFeedbackNotificationContent,
  );

  return {
    CopyErrorData,
    errorClipboardFeedbackNotificationContent,
  };
};

export default useErrorClipboard;
