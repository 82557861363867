import React from 'react';
import { useFormContext, UseFormTrigger } from 'react-hook-form';
import { Button } from '@clarke-energia/foton';

import eventTracker from '@services/eventTracking';
import { eventLabels } from '@services/eventTracking/eventsLabels';

import { ISimulationPayloadBase } from '@schemas/types';

import { getCalculatorName } from '@utils/common';

import SemanticMarquee from '@components/atoms/general/semantic-marquee';
import { useAuth } from '@src/auth-wrapper';
import ErrorMarquee from './general/simulation/error-marquee';
import { IErrorData } from '@utils/error-handling';

interface ICalculatorFormFooter<T> {
  error?: boolean;
  errorData?: IErrorData;
  trigger?: UseFormTrigger<ISimulationPayloadBase>;
  loading: boolean;
  formRef: React.MutableRefObject<HTMLFormElement | null>;
  onSubmit: (values: T) => void;
}

export function CalculatorFormFooter<T extends ISimulationPayloadBase>({
  error,
  errorData,
  trigger,
  loading,
  formRef,
  onSubmit,
}: ICalculatorFormFooter<T>) {
  const { user } = useAuth();
  const { handleSubmit } = useFormContext<T>();

  const handleSubmitButton = () => {
    trigger && trigger(['manualPricesOrDiscountsStartYear']);

    eventTracker.trackEvent(eventLabels.BUTTON_SIMULATE_SIMULATOR, {
      product: getCalculatorName(location.href),
      user_groups: user?.groups ? user?.groups.join(', ') : '',
      user_role: user?.isSuperAdmin ? 'superadmin' : user?.isAdmin ? 'admin' : 'regular',
    });

    if (formRef.current) {
      handleSubmit(onSubmit)();
    }
  };
  return (
    <div className="flex flex-col gap-5 py-12">
      {error && (
        <SemanticMarquee kind="ERROR">
          Existem erros no formulário. Revise as informações e tente novamente.
        </SemanticMarquee>
      )}
      {errorData && <ErrorMarquee errorData={errorData} />}
      <div className="justify-center w-full">
        <Button
          kind="primary"
          type="submit"
          label="Simular"
          disabled={error}
          loading={loading}
          onClick={handleSubmitButton}
          className="w-full"
        />
      </div>
    </div>
  );
}
