import { z } from 'zod';
import { isObjectEmpty } from '@utils/common';
import { TARIFF } from '@utils/constants';
import { IProductOneSimulatorInputs } from '@schemas/product-one/types';
import {
  chargesCoverageTypeForManualAclPricesEnum,
  ISimulationCompetitorPayloadBase,
  ISimulationUnitPayloadBase,
} from '@schemas/types';
import { IProductTwoSimulatorInputs } from '@schemas/product-two/types';
import { getMigrationStartYear } from '@components/molecules/form/simulator/sections/simulator-form-financial-projection-section';

export const checkHasAtLeastOneValue = (obj: Record<string, any> | undefined) => {
  if (!obj) return false;
  return Object.values(obj).some((value) => value > 0);
};

const addIssue = (ctx: z.RefinementCtx, path: Array<string | number>, message: string) => {
  ctx.addIssue({
    code: z.ZodIssueCode.custom,
    path,
    message,
  });
};
const validatePricesByYear = (price: number | undefined, ctx: z.RefinementCtx, fields: Array<string | number>) => {
  if ((price && price < 1) || price === undefined || price === null || isNaN(price)) {
    addIssue(ctx, fields, 'insira um preço');
  }
};
const validateRequiredField = (value: any, path: Array<string | number>, message: string, ctx: z.RefinementCtx) => {
  if (value === undefined || value === null || value === '') {
    addIssue(ctx, path, message);
  }
};

export const validateProductOneSimulatorInputs = (data: IProductOneSimulatorInputs, ctx: z.RefinementCtx) => {
  const {
    aclType,
    aux__useCompetitorList,
    aux__useManualAclPrice,
    aux__useEnergyTypeComparison,
    competitorsList,
    lead,
    manualAclPrice,
    manualAclPricesHasChargesCoverage,
    productType,
    risk,
    targetYear,
    targetYears,
    units,
  } = data as IProductOneSimulatorInputs;
  const startYear = getMigrationStartYear(units as ISimulationUnitPayloadBase[]);

  validateLead(lead, ctx);
  validateManualAclPrice(
    aux__useManualAclPrice,
    manualAclPrice,
    startYear,
    manualAclPricesHasChargesCoverage === 'true',
    ctx,
    targetYear,
    targetYears,
  );
  validateRequiredField(aclType, ['aclType'], 'Tipo de cálculo é obrigatório', ctx);
  validateRequiredField(productType, ['productType'], 'Modalidade de contratação é obrigatório', ctx);
  validateRequiredField(risk, ['risk'], 'Cenário de risco é obrigatório', ctx);

  if (aux__useEnergyTypeComparison) {
    validateRequiredField(data.energyType, ['energyType'], 'Tipo de energia é obrigatório', ctx);
  }
  const target = targetYear ?? (targetYears && targetYears[0]) ?? 3;
  if (aux__useCompetitorList) {
    validateCompetitorList(competitorsList as ISimulationCompetitorPayloadBase[], ctx, target, units);
  }

  units && validateUnits(units, ctx);
};
export const removeYearPrefix = (obj: Record<any, any | null> | null | undefined) => {
  return Object.fromEntries(
    Object.entries(obj ?? {}).map(([key, value]) => {
      const newKey = key.replace('year', '');
      return [Number(newKey), Number(value)];
    }),
  );
};
export const includeYearPrefix = (obj: Record<any, any> | null | undefined) => {
  return Object.fromEntries(
    Object.entries(obj ?? {}).map(([key, value]) => {
      const newKey = key.includes('year') ? key : `year${key}`;
      return [newKey, Number(value)];
    }),
  );
};
export const validateProductTwoSimulatorInputs = (data: IProductTwoSimulatorInputs, ctx: z.RefinementCtx) => {
  const {
    aclType,
    aux__useCompetitorList,
    aux__useManualAclPrice,
    aux__useEnergyTypeComparison,
    customFee,
    competitorsList,
    lead,
    manualAclPrice,
    manualAclPricesHasChargesCoverage,
    manualPricesOrDiscountsStartYear,
    targetYear,
    units,
  } = data;
  validateLead(lead, ctx);
  validateManualAclPrice(
    aux__useManualAclPrice,
    manualAclPrice,
    manualPricesOrDiscountsStartYear,
    manualAclPricesHasChargesCoverage,
    ctx,
    targetYear,
    null,
  );
  validateRequiredField(aclType, ['aclType'], 'Tipo de cálculo é obrigatório', ctx);

  if (aux__useEnergyTypeComparison) {
    validateRequiredField(data?.energyType, ['energyType'], 'Tipo de energia é obrigatório', ctx);
  }

  if (!aux__useManualAclPrice && customFee?.type) {
    const { isMarginUnique, uniqueMargin } = customFee.auxiliarFields ?? {};

    if (isMarginUnique && Number(uniqueMargin) <= 0) {
      validateRequiredField(
        customFee?.auxiliarFields?.uniqueMargin,
        ['customFee.auxiliarFields.uniqueMargin'],
        'Margem única é obrigatória',
        ctx,
      );
    }

    const startYear: number = Number(getMigrationStartYear(units as ISimulationUnitPayloadBase[]));
    const expectedYears = Array.from({ length: targetYear ?? 3 }, (_, i) => startYear + i);

    expectedYears.forEach((year) => {
      const key = `year${year}`;
      const customFeeValuesByKey = (customFee?.values as Record<string, number | null | undefined>)?.[key];

      if (!customFeeValuesByKey) {
        validateRequiredField(customFeeValuesByKey, ['customFee', 'values', key], 'Campo é obrigatório', ctx);
      }
    });
  }
  if (aux__useCompetitorList) {
    validateCompetitorList(competitorsList as ISimulationCompetitorPayloadBase[], ctx, targetYear, units);
  }

  units && validateUnits(units, ctx);
};

export const transformManualAclPrice = (
  targetYear: number | null | undefined,
  manualAclPrice: any,
  years: number[],
) => {
  return years?.reduce((acc, year, index) => {
    // @ts-expect-error
    acc[year] = manualAclPrice?.[targetYear]?.[index] ?? null;
    return acc;
  }, {});
};
const managementTypeEnum = {
  SALES_MANAGER: 'SALES_MANAGER',
  INDEPENDENT_MANAGER: 'INDEPENDENT_MANAGER',
};
export const validateCompetitorList = (
  competitorsListWithClarke: ISimulationCompetitorPayloadBase[],
  ctx: z.RefinementCtx,
  targetYear: number | null | undefined,
  units: ISimulationUnitPayloadBase[] | null | undefined,
) => {
  const startYear = getMigrationStartYear(units as ISimulationUnitPayloadBase[]);

  const competitorsList = competitorsListWithClarke?.slice(1);

  if (competitorsList?.length < 1) {
    addIssue(ctx, ['aux__useCompetitorsList'], 'É necessário adicionar pelo menos 1 concorrente além da Clarke.');
    return;
  }

  const requiredFields = [
    {
      value: competitorsListWithClarke?.[0]?.guaranteeType,
      path: ['competitorsList', 0, 'guaranteeType'],
      message: 'Tipo de garantia é obrigatório',
    },
    {
      value: competitorsListWithClarke?.[0]?.lowerFlexibility,
      path: ['competitorsList', 0, 'lowerFlexibility'],
      message: 'Flexibilidade inferior é obrigatória',
    },
    {
      value: competitorsListWithClarke?.[0]?.upperFlexibility,
      path: ['competitorsList', 0, 'upperFlexibility'],
      message: 'Flexibilidade superior é obrigatória',
    },
  ];
  if (
    !competitorsListWithClarke?.[0]?.managerType?.includes(managementTypeEnum.SALES_MANAGER) &&
    !competitorsListWithClarke?.[0]?.managerType?.includes(managementTypeEnum.INDEPENDENT_MANAGER)
  ) {
    addIssue(ctx, ['competitorsList', 0, 'managerType'], 'Tipo de gestor é obrigatórios');
  }

  requiredFields.forEach((field) => validateRequiredField(field.value, field.path, field.message, ctx));

  competitorsList?.forEach((competitors, i) => {
    const index = i + 1;
    let { aclPrices } = competitors;
    const AclPricesSchema = z.record(z.number(), z.number());
    const isValidAclPrices = AclPricesSchema.safeParse(aclPrices).success;
    if (!isValidAclPrices) {
      for (let i = 0; i < (targetYear ?? 3); i++) {
        const rawYear = Number(startYear) + i;
        const year = rawYear.toString().includes('year') ? rawYear : `year${rawYear}`;
        validatePricesByYear((aclPrices as { [key: string]: number })?.[year], ctx, [
          'competitorsList',
          index,
          'aclPrices',
          year,
        ]);
      }
    }

    const competitorFields = [
      {
        value: competitors?.chargesCoverageType,
        path: ['competitorsList', index, 'chargesCoverageType'],
        message: 'Tipo de cobertura de encargos é obrigatório',
      },
      {
        value: competitors?.monthlyManagementPricePerUnit,
        path: ['competitorsList', index, 'monthlyManagementPricePerUnit'],
        message: 'Preço de gestão mensal por unidade é obrigatório',
      },
      {
        value: competitors?.productType,
        path: ['competitorsList', index, 'productType'],
        message: 'Tipo de produto é obrigatório',
      },
      {
        value: competitors?.guaranteeType,
        path: ['competitorsList', index, 'guaranteeType'],
        message: 'Tipo de garantia é obrigatório',
      },
      {
        value: competitors?.lowerFlexibility,
        path: ['competitorsList', index, 'lowerFlexibility'],
        message: 'Flexibilidade inferior é obrigatória',
      },
      {
        value: competitors?.upperFlexibility,
        path: ['competitorsList', index, 'upperFlexibility'],
        message: 'Flexibilidade superior é obrigatória',
      },
      {
        value: competitors?.managementTerminationFine,
        path: ['competitorsList', index, 'managementTerminationFine'],
        message: 'Multa de rescisão de gestão é obrigatório',
      },
    ];
    if (
      !competitors?.managerType?.includes(managementTypeEnum.SALES_MANAGER) &&
      !competitors?.managerType?.includes(managementTypeEnum.INDEPENDENT_MANAGER)
    ) {
      addIssue(ctx, ['competitorsList', index, 'managerType'], 'Tipo de gestor é obrigatório');
    }
    competitorFields.forEach((field) => validateRequiredField(field.value, field.path, field.message, ctx));
  });
};

export const validateLead = (lead: string | null | undefined, ctx: z.RefinementCtx) => {
  validateRequiredField(lead, ['lead'], 'Grupo Comercial é obrigatório', ctx);
};

export const validateUnits = (units: Array<ISimulationUnitPayloadBase>, ctx: z.RefinementCtx) => {
  units?.forEach((unit, index) => {
    const {
      nickname,
      supplyStartDate,
      energyDistributionCompany,
      icmsType,
      tariff,
      consumptionOffPeak,
      consumptionPeak,
      managementPrice,
      aclContractedDemandOffPeak,
      acrContractedDemandOffPeak,
    } = unit;

    const unitFields = [
      { value: nickname, path: ['units', index, 'nickname'], message: 'Nome da unidade é obrigatório' },
      { value: supplyStartDate, path: ['units', index, 'supplyStartDate'], message: 'Data de migração é obrigatória' },
      { value: managementPrice, path: ['units', index, 'managementPrice'], message: 'Preço de gestão é obrigatório' },
      { value: tariff, path: ['units', index, 'tariff'], message: 'Tarifa é obrigatória' },
      {
        value: energyDistributionCompany,
        path: ['units', index, 'energyDistributionCompany'],
        message: 'Distribuidora de energia é obrigatória',
      },
      { value: icmsType, path: ['units', index, 'icmsType'], message: 'Tipo de ICMS é obrigatório' },
    ];

    unitFields.forEach((field) => validateRequiredField(field.value, field.path, field.message, ctx));

    const isLowVoltage = tariff === TARIFF.B3_STANDARD || tariff === TARIFF.B3_OPTING;

    if (!isLowVoltage) {
      validateConsumption(consumptionPeak, 'consumptionPeak', index, ctx);
      validateConsumption(consumptionOffPeak, 'consumptionOffPeak', index, ctx);
    } else {
      validateLowVoltageFields(unit, index, ctx);
    }

    if (!isLowVoltage) {
      validateContractedDemand(aclContractedDemandOffPeak, 'aclContractedDemandOffPeak', index, ctx);
      validateContractedDemand(acrContractedDemandOffPeak, 'acrContractedDemandOffPeak', index, ctx);
    }
  });
};

const validateConsumption = (
  consumption: Record<string, any> | undefined,
  field: string,
  index: number,
  ctx: z.RefinementCtx,
): void => {
  if (!consumption || isObjectEmpty(consumption)) {
    for (let i = 1; i <= 12; i++) {
      addIssue(ctx, ['units', index, field, i], 'Insira um valor');
    }
  }
};

const validateOperationHours = (operationHoursRange: any, weekPeriod: string, index: number, ctx: z.RefinementCtx) => {
  const openingTime = operationHoursRange?.[weekPeriod]?.openingTime ?? '';
  const closingTime = operationHoursRange?.[weekPeriod]?.closingTime ?? '';
  if (!openingTime || openingTime?.length !== 4) {
    addIssue(ctx, ['units', index, 'operationHoursRange', weekPeriod, 'openingTime'], 'Horário de abertura inválido');
  }
  if (!closingTime || closingTime?.length !== 4) {
    addIssue(ctx, ['units', index, 'operationHoursRange', weekPeriod, 'closingTime'], 'Horário de fechamento inválido');
  }
  if (Number(openingTime) >= Number(closingTime)) {
    addIssue(
      ctx,
      ['units', index, 'operationHoursRange', weekPeriod, 'closingTime'],
      'Horário de fechamento deve ser maior que o horário de abertura',
    );
  }
};

const validateLowVoltageFields = (unit: ISimulationUnitPayloadBase, index: number, ctx: z.RefinementCtx): void => {
  const {
    companyType,
    operationHoursRange,
    aux__useOpenInWeekends,
    aux__useOperationInSaturdays,
    aux__useOperationInSundays,
  } = unit;

  const lowVoltageFields = [
    { value: companyType, path: ['units', index, 'companyType'], message: 'Campo obrigatório' },
    {
      value: operationHoursRange?.businessDays?.openingTime,
      path: ['units', index, 'operationHoursRange', 'businessDays', 'openingTime'],
      message: 'Horário de abertura inválido',
    },
    {
      value: operationHoursRange?.businessDays?.closingTime,
      path: ['units', index, 'operationHoursRange', 'businessDays', 'closingTime'],
      message: 'Horário de fechamento inválido',
    },
  ];

  lowVoltageFields.forEach((field) => validateRequiredField(field.value, field.path, field.message, ctx));

  if (
    !unit.consumptionLowVoltage ||
    (unit.consumptionLowVoltage && !checkHasAtLeastOneValue(unit.consumptionLowVoltage))
  ) {
    for (let i = 1; i <= 12; i++) {
      addIssue(ctx, ['units', index, 'consumptionLowVoltage', i], 'Insira um valor');
    }
  }

  if (aux__useOperationInSaturdays) {
    validateOperationHours(operationHoursRange, 'saturday', index, ctx);
  }

  if (aux__useOpenInWeekends) {
    validateWeekendOperations(
      operationHoursRange,
      aux__useOperationInSaturdays as boolean,
      aux__useOperationInSundays as boolean,
      index,
      ctx,
    );
    validateOperationHours(operationHoursRange, 'businessDays', index, ctx);
  } else {
    validateOperationHours(operationHoursRange, 'businessDays', index, ctx);
  }
};
const selectedWeekPeriod = (aux__useOperationInSaturdays: boolean, aux__useOperationInSundays: boolean) => {
  if (aux__useOperationInSaturdays && !aux__useOperationInSundays) return 'saturday';
  if (!aux__useOperationInSaturdays && aux__useOperationInSundays) return 'sunday';
  return 'weekend';
};
const validateWeekendOperations = (
  operationHoursRange: any,
  aux__useOperationInSaturdays: boolean,
  aux__useOperationInSundays: boolean,
  index: number,
  ctx: z.RefinementCtx,
): void => {
  const weekPeriod = selectedWeekPeriod(aux__useOperationInSaturdays, aux__useOperationInSundays);
  const buildDayFields = (day: 'saturday' | 'sunday', index: number) => [
    {
      value: operationHoursRange?.[day]?.openingTime,
      path: ['units', index, 'operationHoursRange', day, 'openingTime'],
      message: 'Insira um horário de abertura',
    },
    {
      value: operationHoursRange?.[day]?.closingTime,
      path: ['units', index, 'operationHoursRange', day, 'closingTime'],
      message: 'Insira um horário de fechamento',
    },
  ];

  const weekendFields = {
    saturday: buildDayFields('saturday', index),
    sunday: buildDayFields('sunday', index),
    weekend: [...buildDayFields('saturday', index), ...buildDayFields('sunday', index)],
  };
  if (aux__useOperationInSaturdays || (!aux__useOperationInSundays && !aux__useOperationInSaturdays)) {
    validateOperationHours(operationHoursRange, 'saturday' as string, index, ctx);
  }
  if (aux__useOperationInSundays) {
    validateOperationHours(operationHoursRange, 'sunday' as string, index, ctx);
  }

  weekendFields[weekPeriod].forEach(({ value, path, message }) => {
    validateRequiredField(value, path, message, ctx);
  });
};

const validateContractedDemand = (demand: any, field: string, index: number, ctx: z.RefinementCtx): void => {
  validateRequiredField(demand, ['units', index, field], 'Demanda contratada é obrigatória', ctx);
};

export const validateManualAclPrice = (
  aux__useManualAclPrice: boolean | undefined,
  manualAclPrice: any,
  manualPricesOrDiscountsStartYear: number | null | undefined,
  manualAclPricesHasChargesCoverage: boolean | 'true' | 'false' | null | undefined,
  ctx: z.RefinementCtx,
  targetYear: number | null | undefined,
  targetYears: number[] | null | undefined,
) => {
  if (aux__useManualAclPrice) {
    const selectedYear = targetYears && targetYears.length > 0 ? targetYears[0] : targetYear;
    const startYear = manualPricesOrDiscountsStartYear as number;

    for (let i = 0; i < (selectedYear ?? 3); i++) {
      const year = startYear + i;
      const priceByYear = manualAclPrice?.[selectedYear as number]?.[`year${year}`];
      validatePricesByYear(Number(priceByYear), ctx, [
        'manualAclPrice',
        selectedYear as number,
        year.toString().includes('year') ? year.toString() : `year${year}`,
      ]);
    }
    if (chargesCoverageTypeForManualAclPricesEnum.safeParse(manualAclPricesHasChargesCoverage).data === undefined) {
      addIssue(ctx, ['manualAclPricesHasChargesCoverage'], 'Tipo de cobertura de encargos é obrigatório');
    }
  }
};
