import React from 'react';
import { createContext } from 'use-context-selector';
import axios, { AxiosPromise } from 'axios';
import config from '@config';

import { IManagementContractPayload, IManagementContract, IManagementContractContext } from './types';

export const ManagementContractContext = createContext({} as IManagementContractContext);

interface Provider {
  children: React.ReactNode;
}

const ManagementContractProvider: React.FC<Provider> = ({ children }) => {
  const currentDomain = window.location.origin;

  const serverPort = config.SERVER_PORT ?? '5000';
  const serverBaseURL = `${currentDomain.replace(
    /:\d+/,
    `${config.IS_IN_PRODUCTION === 'true' ? '' : `:${serverPort}`}`,
  )}/api/management-contract`;
  const serverApiClient = axios.create({
    baseURL: serverBaseURL,
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  function getManagementContractDraft(userId: string, proposalId: string): AxiosPromise {
    return serverApiClient.get('/draft', {
      params: {
        user_id: userId,
        proposal_id: proposalId,
      },
    });
  }
  function getManagementContracts(product: string, status: string, proposalId?: string): AxiosPromise {
    return serverApiClient.get('/all', {
      params: {
        product: product,
        status: status,
        proposal_id: proposalId,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  function createManagementContract(contract: IManagementContractPayload): AxiosPromise {
    return serverApiClient.post('/', contract);
  }

  function createManagementContractDraft(draft: IManagementContractPayload): AxiosPromise {
    return serverApiClient.post('/draft', draft, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function updateManagementContractDraft(draft: IManagementContract): AxiosPromise {
    return serverApiClient.post(`/draft/${draft.id}`, draft, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }

  function publishManagementContractDraft(contract: IManagementContract): AxiosPromise {
    return serverApiClient.post('/publish', contract, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  }

  return (
    <ManagementContractContext.Provider
      value={{
        createManagementContractDraft,
        updateManagementContractDraft,
        publishManagementContractDraft,
        getManagementContractDraft,
        getManagementContracts,
        createManagementContract,
      }}
    >
      {children}
    </ManagementContractContext.Provider>
  );
};

export default ManagementContractProvider;
