import React, { useState } from 'react';
import { createContext } from 'use-context-selector';
import { FeedbackNotificationProps } from '@clarke-energia/foton';

import { IErrorClipboardContext } from './types';
import {
  errorClipboardFeedbackNotificationContents,
  generateTextFromErrorData,
  setErrorClipboardValue,
  writeUserClipboard,
} from './helper';

export const ErrorClipboardContext = createContext({} as IErrorClipboardContext);

interface Provider {
  children: React.ReactNode;
}

export const ErrorClipboardProvider: React.FC<Provider> = ({ children }: Provider) => {
  const [errorClipboardFeedbackNotificationContent, setErrorClipboardFeedbackNotificationContent] =
    useState<FeedbackNotificationProps>();

  const feedbackNotificationsContents = errorClipboardFeedbackNotificationContents(() =>
    setErrorClipboardFeedbackNotificationContent(undefined),
  );

  const getSimulatorName = (): string => {
    const isManagementPath = window.location.pathname.includes('clarke-gestao');
    const isRetailPath = window.location.pathname.includes('clarke-varejo');
    const simulatorName = isManagementPath ? 'clarke-gestao' : isRetailPath ? 'clarke-varejo' : '';
    return simulatorName;
  };

  function CopyErrorData(errorData?: any, leadName?: string) {
    if (errorData) {
      const simulatorName = getSimulatorName();
      const parsedErrorData = generateTextFromErrorData(errorData, leadName, simulatorName);
      setErrorClipboardValue(parsedErrorData);
      writeUserClipboard(parsedErrorData)
        .then(() => {
          setErrorClipboardFeedbackNotificationContent(feedbackNotificationsContents.COPY_SUCCESS);
        })
        .catch(() => {
          setErrorClipboardFeedbackNotificationContent(feedbackNotificationsContents.COPY_ERROR);
        });
    }
  }

  React.useEffect(() => {
    setErrorClipboardFeedbackNotificationContent(undefined);
  }, [location.href]);

  return (
    <ErrorClipboardContext.Provider value={{ CopyErrorData, errorClipboardFeedbackNotificationContent }}>
      {children}
    </ErrorClipboardContext.Provider>
  );
};
