export interface IErrorDataFromRequest {
  code?: string;
  errorCode?: string;
  message?: string;
  errorType?: string;
  errorFieldsDescription?: any;
  requestParams?: any;
  inputs?: Record<string, any>;
}

export interface IErrorData {
  errorCode?: string;
  errorMessagePt?: string;
  errorType?: string;
  errorFieldsDescription?: any;
  requestParams?: any;
  inputs?: Record<string, any>;
}

export const getErrorMessage = (errorData: any): string => {
  const { errorMessagePt = 'Erro na simulação tente novamente ou ative o suporte técnico', errorCode = '' } =
    errorData || {};

  const errorMessage = errorCode ? `${errorCode}: ${errorMessagePt}` : `${errorMessagePt}`;

  return errorMessage;
};

export const parseErrorData = (errorData: IErrorDataFromRequest | undefined): IErrorData | undefined => {
  if (errorData) {
    const parsedErrorData = {
      errorCode: errorData?.errorCode ?? errorData?.code,
      errorMessagePt: errorData?.message,
      errorType: errorData?.errorType,
      errorFieldsDescription: errorData?.errorFieldsDescription,
      requestParams: errorData?.requestParams,
      inputs: errorData?.inputs,
    };
    return parsedErrorData;
  }
};
