import React from 'react';
import axios, { AxiosPromise } from 'axios';
import { createContext } from 'use-context-selector';

import { IResponseBase, SimulatorTypeEnum } from '@schemas/types';
import config from '@config';

import { ILeadPayLoad, ILeadsContext } from './types';

export const LeadsContext = createContext({} as ILeadsContext);

interface Provider {
  children: React.ReactNode;
}

const LeadsProvider: React.FC<Provider> = ({ children }) => {
  const currentDomain = window.location.origin;
  const serverPort = config.SERVER_PORT ?? '5000';
  const serverBaseURL = `${currentDomain.replace(
    /:\d+/,
    `${config.IS_IN_PRODUCTION === 'true' ? '' : `:${serverPort}`}`,
  )}/api/leads`;
  const serverApiClient = axios.create({
    baseURL: serverBaseURL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  async function createLead(leadPayLoad: ILeadPayLoad): AxiosPromise<IResponseBase> {
    return await serverApiClient.post('/', leadPayLoad);
  }

  async function getLeads(
    page?: number,
    name?: string,
    ignoreLeadsWithoutProposals?: boolean,
    simulatorName?: keyof typeof SimulatorTypeEnum,
    sellerId?: string,
  ): AxiosPromise<IResponseBase> {
    try {
      const legacySimulatorNamesIndexer: Record<keyof typeof SimulatorTypeEnum, string> = {
        CLARKE_MANAGEMENT: 'PRODUCT_ONE',
        CLARKE_RETAIL: 'PRODUCT_TWO',
        CONTRACTED_DEMAND: 'CONTRACTED_DEMAND',
        WHITE_LABEL: 'WHITE LABEL',
      };

      const leads = serverApiClient.get('/', {
        params: {
          page: page,
          term: name,
          ignore_leads_without_proposals: ignoreLeadsWithoutProposals,
          simulator_name: simulatorName && legacySimulatorNamesIndexer[simulatorName],
          linked_user_id: sellerId,
        },
      });
      return leads;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  async function getLeadById(id: string): AxiosPromise<IResponseBase> {
    return await serverApiClient.get(`/${id}`);
  }
  return <LeadsContext.Provider value={{ getLeads, createLead, getLeadById }}>{children}</LeadsContext.Provider>;
};

export default LeadsProvider;
