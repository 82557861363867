import { User } from '@auth0/auth0-react';
import config from '@config';
import { UserGroup } from '@contexts/users/types';
import axios, { AxiosPromise } from 'axios';

export default class Auth0ApiService {
  currentDomain: string;
  serverBaseURL: string;
  serverApiClient: ReturnType<typeof axios.create>;

  constructor() {
    this.currentDomain = window.location.origin;

    this.serverBaseURL = `${config.SERVER_BASE_URL}/api`;
    this.serverApiClient = axios.create({
      baseURL: this.serverBaseURL,
      withCredentials: true,
    });
  }

  executeAuth0ApiRequest = (
    endpoint: string,
    getRequest?: boolean,
    payload?: Record<string, any>,
    additionalParams?: Record<string, any>,
  ): AxiosPromise => {
    const commonHeaders = {
      headers: {
        'Content-Type': 'application/json',
      },
      params: {
        ...additionalParams,
      },
    };

    if (getRequest) {
      return this.serverApiClient.get(endpoint, commonHeaders);
    } else {
      return this.serverApiClient.post(endpoint, payload, commonHeaders);
    }
  };

  getUsers(userAttribute?: string): AxiosPromise<Array<User>> {
    const includeNeverLoggedUsersParam = '(NOT _exists_:logins_count OR logins_count:0 OR logins_count:[0 TO *])';
    const filterUserByNameOrEmailParam = `(email:*${userAttribute}* OR name:*${userAttribute}*)`;
    const finalQueryString = `${includeNeverLoggedUsersParam} ${
      userAttribute ? `AND ${filterUserByNameOrEmailParam}` : ''
    }`;

    return this.executeAuth0ApiRequest('/users', true, undefined, { search_engine: 'v3', q: finalQueryString });
  }

  getSellersUsers(group: UserGroup, name?: string, isWhiteLabel?: boolean): AxiosPromise<Array<User>> {
    try {
      const includeNeverLoggedUsersParam = '(NOT _exists_:logins_count OR logins_count:0 OR logins_count:[0 TO *])';
      const filterUserBySellerGroup = `(app_metadata.authorization.groups:"${group}")`;
      const filterUserByName = `(name:*${name ?? ''}*)`;
      const finalQueryString = isWhiteLabel
        ? `${includeNeverLoggedUsersParam} AND ${filterUserByName}`
        : `${includeNeverLoggedUsersParam} AND ${filterUserBySellerGroup} AND ${filterUserByName}`;

      return this.executeAuth0ApiRequest('/users/sellers', true, undefined, {
        search_engine: 'v3',
        q: finalQueryString,
      });
    } catch (e: any) {
      throw new Error(`Error getting sellers users: ${e.message}`);
    }
  }

  getUserById(userId: string): AxiosPromise<User> {
    const userIdFilter = `app_metadata.uuid:("${userId}")`;

    return this.executeAuth0ApiRequest('/users', true, undefined, { search_engine: 'v3', q: userIdFilter });
  }

  getUsersByIdList(usersId: string[]): AxiosPromise<Array<User>> {
    const usersIdListFilter = `app_metadata.uuid:(${usersId.map((userId) => `"${userId}"`)})`;

    return this.executeAuth0ApiRequest('/users', true, undefined, { search_engine: 'v3', q: usersIdListFilter });
  }
}
